<template>
  <Loader
    class="mt-12 flex justify-center items-center"
    v-if="showLoaderComp"
  />

  <div v-else>
    <div v-if="privat == true">
      <div class="flex justify-start items-end">
        <div>
          <HeaderGlobal titlemenu1="" class="pl-4" />
        </div>
      </div>
      <div
        v-if="biometricData"
        class="mt-12 flex flex-col justify-center items-center"
      >
        <div class="w-5/6 font-proximaMedium relative">
          <div class="absolute">
            <img
              class="h-6/6 w-6/6"
              src="@/assets/images/cadre.svg"
              alt="dialog-box"
            />
          </div>
          <div
            class="pt-5 md:pt-14 w-full flex items-start justify-start relative z-10"
          >
            <div
              class="lg:mx-14 md:mx-12 mx-8 relative cursor-pointer"
              @click="profilePictureModals = true"
            >
              <img
                class="mt-6 border border-primary rounded-full"
                style="width: 102px; height: 102px"
                :src="picture"
                alt="pet_icon"
              />
            </div>
            <ul
              class="lg:w-4/12 md:w-5/12 text-left text-sm md:text-base font-proximaLight list-disc"
            >
              <li class="my-2 text-primary">
                <span class="text-black font-bold">{{
                  biometricData.callName
                }}</span>
              </li>
              <li v-if="ownerData" class="my-2 text-primary">
                <span class="text-black font-bold">{{
                  ownerData.firstName + " " + ownerData.lastName
                }}</span>
              </li>
              <li class="my-2 text-primary">
                <span class="text-black font-bold">
                  {{ biometricData.age }}</span
                >
              </li>
              <li class="my-2 text-primary">
                <span class="text-black font-bold">{{
                  biometricData.breeding_sex
                }}</span>
              </li>
              <li class="my-2 text-primary" v-if="biometricData.height_weight">
                <span class="text-black font-bold"
                  >{{ biometricData.height_weight }}
                </span>
              </li>
              <!--  <li class="my-2 text-primary">
                <span class="text-black font-bold"> </span>
              </li> -->
            </ul>
          </div>
        </div>
      </div>
      <div
        class="mt-16 md:mt-32 flex flex-col md:flex-row items-center md:items-end"
      >
        <img
          class="w-44"
          src="@/assets/images/fluff_1.svg"
          alt="standing-logo"
        />
        <div>
          <div
            class="mt-3 w-full text-primary px-12 py-1 border border-primary rounded-md flex items-center cursor-pointer"
            @click="openConnectRequestModals"
          >
            <img src="@/assets/images/wifi.png" alt="standing-logo" />
            <span>I’d like to connect.</span>
          </div>
          <div
            class="mt-2 w-full text-primary bg-white px-16 py-4 border border-primary rounded-md cursor-pointer"
            @click="openReadMoreModals"
          >
            Read More About Breed
          </div>
        </div>
      </div>

      <!-- profile picture -->
      <ModalsConfirm :show="profilePictureModals">
        <div slot="actionQuestion" class="relative">
          <div
            class="absolute -top-0 right-0 text-2xl font-proximaSemibold cursor-pointer"
            @click="profilePictureModals = false"
          >
            ✖
          </div>
        </div>
        <div slot="actionText" class="mt-8 mb-4">
          <div>
            <vue-image-slider
              class="mx-auto hidden md:block"
              :images="imageArray"
              :intervalVal="3000"
              :height="300"
              :width="400"
            />
            <vue-image-slider
              class="mx-auto block md:hidden"
              :images="imageArray"
              :intervalVal="3000"
              :height="160"
              :width="250"
            />
          </div>
        </div>
        <div slot="leftBtn"></div>
        <div slot="rightBtn"></div>
      </ModalsConfirm>
      <!-- profile picture end -->
      <!-- I’d like to connect. -->
      <ModalsConfirm class="w-5/12" :show="connectRequestModals">
        <div slot="actionQuestion" class="relative">
          <div
            class="absolute -top-0 right-0 text-lg font-proximaSemibold cursor-pointer"
            @click="closeContactRequest"
          >
            ✖
          </div>
          <h1 class="text-2xl">Contact Request</h1>
        </div>
        <div slot="actionText" class="mt-8 mb-4">
          <FormError :errors="errorForm" />
          <h2 class="text-left font-proximaLight text-xl mb-5">
            {{ $t("add_not_modals_title") }}
          </h2>
          <div>
            <textarea
              class="w-full p-2 border-2 border-primary rounded-md text-lg"
              cols="30"
              rows="5"
              v-model="note"
              placeholder="Add a note to your request to introduce yourself and explain why you want to connect."
            ></textarea>
          </div>
        </div>
        <BorderedButton
          slot="leftBtn"
          class="md:mr-1"
          :title="$t('global_cancel')"
          borderColor="border-mediumGrey"
          textCl="text-darkGrey"
          paddingX="px-8"
          width="w-full"
          @click.native="closeContactRequest"
        />

        <SubmitButton
          slot="rightBtn"
          class="md:ml-1"
          title="Confirm"
          background="bg-success"
          paddingX="px-8"
          width="w-full"
          @click.native="submitConnectRequest"
        />
      </ModalsConfirm>
      <!-- read more pdf. -->
      <ModalsConfirm class="w-5/12" :show="readMoreModals">
        <div slot="actionQuestion" class="relative">
          <div
            class="absolute -top-0 right-0 text-lg font-proximaSemibold cursor-pointer"
            @click="readMoreModals = false"
          >
            ✖
          </div>
        </div>
        <div slot="actionText" class="mt-8 mb-4">
          <iframe
            src="http://www.fci.be/Nomenclature/Standards/001g07-en.pdf"
            style="width: 100%; height: 300px; border: none"
          >
            Oops! an error has occurred.
          </iframe>
        </div>
      </ModalsConfirm>
    </div>

    <div v-else>
      <div class="flex justify-start items-end">
        <div>
          <HeaderGlobal
            :titlemenu1="titlemenu2"
            :titlemenu2="title"
            class="pl-4"
          />
        </div>
      </div>
      <div
        v-if="errorForm"
        class="mt-12 flex flex-col justify-center items-center"
      >
        <FormError :errors="errorForm" />
      </div>
      <div v-else>
        <div
          v-if="biometricData"
          class="mt-12 flex flex-col justify-center items-center"
        >
          <div class="w-full font-proximaMedium">
            <h3 class="mb-5 text-2xl uppercase">
              {{ biometricData.callName }}
            </h3>
            <div class="w-full flex items-start justify-evenly">
              <div
                class="relative cursor-pointer"
                @click="profilePictureModals = true"
              >
                <img
                  class="mt-6 border border-primary rounded-full"
                  style="width: 102px; height: 102px"
                  :src="picture"
                  alt="pet_icon"
                />
                <!-- ownerData.profileImage -->
                <!-- v-if="ownerData" -->
                <img
                  v-if="ownerData"
                  class="w-12 h-12 border border-white rounded-full absolute top-2 -right-3"
                  :src="ownerData.profileImage"
                  alt="pp-lg"
                />
                <!--  <img v-else class="
                w-12
                h-12
                border border-white
                rounded-full
                absolute
                top-2
                -right-3
              " src="@/assets/images/pp-lg.png" alt="pp-lg" />  -->
              </div>
              <ul class="text-left font-proximaLight list-disc">
                <li v-if="ownerData" class="my-2 text-primary">
                  <span class="text-black">{{
                    ownerData.firstName + " " + ownerData.lastName
                  }}</span>
                </li>
                <li class="my-2 text-primary">
                  <span class="text-black">{{ biometricData.age }}</span>
                </li>
                <li class="my-2 text-primary">
                  <span class="text-black">{{
                    biometricData.breeding_sex
                  }}</span>
                </li>
                <li
                  v-if="biometricData.height_weight"
                  class="my-2 text-primary"
                >
                  <span class="text-black">{{
                    biometricData.height_weight
                  }}</span>
                </li>
                <!-- <li class="my-2 text-primary">
              <span class="text-black">{{ biometricData.blood }}</span>
            </li> -->
              </ul>
            </div>
            <div>
              <ul class="mt-4 flex flex-wrap">
                <!--  <li
              class="mt-6 w-6/12 flex flex-col items-center cursor-pointer"
              @click="goToCareBlog"
            >
              <img
                class="mb-2 h-28 w-28"
                src="@/assets/images/careblog-icon.png"
                alt="medicalhistory-icon"
              />
              <span>Diary</span>
            </li>-->
                <li
                  class="mt-6 w-6/12 flex flex-col items-center cursor-pointer"
                ></li>
                <li
                  v-if="Object.keys(symptomTracker).length > 0"
                  class="mt-6 w-6/12 flex flex-col items-center cursor-pointer"
                  @click="goToMedicalHistory"
                >
                  <img
                    class="mb-2 h-28 w-28"
                    src="@/assets/images/medicalhistory-icon.png"
                    alt="medicalhistory-icon"
                  />
                  <span>Medical History</span>
                </li>
                <li
                  class="mt-6 w-6/12 flex flex-col items-center cursor-pointer"
                  v-on:click="goToCareRoutine"
                  v-if="careRoutine.length > 0"
                >
                  <img
                    class="mb-2 h-28 w-28"
                    src="@/assets/images/careroutine-icon.png"
                    alt="careroutine-icon"
                  />
                  <span>{{ $t("pet_dropdown_menu_care_routine") }}</span>
                </li>
                <li
                  class="mt-6 w-6/12 flex flex-col items-center cursor-pointer"
                  @click="emergencyContactModals = true"
                >
                  <img
                    class="mb-2 h-28 w-28"
                    src="@/assets/images/emergencycontact-icon.png"
                    alt="emergencycontact-icon"
                  />
                  <span>Emergency Contacts</span>
                </li>
                <!-- <li
              class="mt-6 w-6/12 flex flex-col items-center cursor-pointer"
              @click="favoriteServiceModals = true"
            >
              <img
                class="mb-2 h-28 w-28"
                src="@/assets/images/favoriteservices-icon.png"
                alt="favoriteservices-icon"
              />
              <span>Favorites Services</span>
            </li> -->
                <!-- <li
              class="mt-6 w-6/12 flex flex-col items-center cursor-pointer"
              @click="noteModals = true"
            >
              <img
                class="mb-2 h-28 w-28"
                src="@/assets/images/notes-icon.png"
                alt="notes-icon"
              />
              <span>Notes</span>
            </li> -->
              </ul>
            </div>
            <!--  <div>
          <ul class="mt-4 flex flex-wrap">
            <li
              class="mt-6 w-6/12 flex flex-col items-center cursor-pointer"
              @click="emergencyContactModals = true"
            >
              <img
                class="mb-2 h-28 w-28"
                src="@/assets/images/careblog-icon.png"
                alt="medicalhistory-icon"
              />
              <span>Diary</span>
            </li>
            <li
              class="mt-6 w-6/12 flex flex-col items-center cursor-pointer"
              @click="emergencyContactModals = true"
            >
              <img
                class="mb-2 h-28 w-28"
                src="@/assets/images/medicalhistory-icon.png"
                alt="medicalhistory-icon"
              />
              <span>Medical History</span>
            </li>
            <li
              class="mt-6 w-6/12 flex flex-col items-center cursor-pointer"
              v-on:click="goToCareRoutine"
            >
              <img
                class="mb-2 h-28 w-28"
                src="@/assets/images/careroutine-icon.png"
                alt="careroutine-icon"
              />
              <span>{{ $t("pet_dropdown_menu_care_routine") }}</span>
            </li>
            <li
              class="mt-6 w-6/12 flex flex-col items-center cursor-pointer"
              @click="favoriteServiceModals = true"
            >
              <img
                class="mb-2 h-28 w-28"
                src="@/assets/images/favoriteservices-icon.png"
                alt="favoriteservices-icon"
              />
              <span>Favorites Services</span>
            </li>
            <li
              class="mt-6 w-6/12 flex flex-col items-center cursor-pointer"
              @click="noteModals = true"
            >
              <img
                class="mb-2 h-28 w-28"
                src="@/assets/images/notes-icon.png"
                alt="notes-icon"
              />
              <span>Notes</span>
            </li>
          </ul>
        </div> -->
          </div>
        </div>

        <!-- emergency contact -->
        <ModalsConfirm :show="emergencyContactModals">
          <div slot="actionQuestion" class="relative">
            <div
              class="absolute -top-0 right-0 text-2xl font-proximaSemibold cursor-pointer"
              @click="emergencyContactModals = false"
            >
              ✖
            </div>
          </div>
          <div slot="actionText" class="mt-8 mb-4">
            <div>
              <h3 class="mb-4 text-2xl font-bold font-proximaMedium">
                Emergency Contacts
              </h3>
              <div class="h-52 overflow-y-auto">
                <div
                  class="mb-4 text-left flex items-center"
                  v-for="(data, i) in emergencyContacts"
                  :key="i"
                >
                  <img
                    v-if="data.profile_picture"
                    class="h-10 w-10 rounded-full"
                    :src="data.profile_picture"
                    :alt="data.name"
                  />
                  <img
                    v-else
                    class="h-10 w-10"
                    :src="require('@/assets/images/pp-3.png')"
                    :alt="data.name"
                  />
                  <div class="pl-2">
                    <h5 class="text-xl font-proximaNormal">
                      {{ data.name || data.contactEmailAddress }}
                      {{ data.contactphoneNo ? "(" : "" }}
                      <a :href="'tel:' + data.contactphoneNo">{{
                        data.contactphoneNo
                      }}</a>
                      {{ data.contactphoneNo ? ")" : "" }}
                    </h5>
                    <!-- <span>{{ data.address }}</span> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div slot="leftBtn"></div>
          <div slot="rightBtn"></div>
        </ModalsConfirm>
        <!-- emergency contact end -->
        <!-- profile picture -->
        <ModalsConfirm
          :show="profilePictureModals"
          v-if="imageArray.length > 0"
        >
          <div slot="actionQuestion" class="relative">
            <div
              class="absolute -top-0 right-0 text-2xl font-proximaSemibold cursor-pointer"
              @click="profilePictureModals = false"
            >
              ✖
            </div>
          </div>
          <div slot="actionText" class="mt-8 mb-4">
            <div>
              <vue-image-slider
                class="mx-auto hidden md:block"
                :images="imageArray"
                :intervalVal="3000"
                :height="300"
                :width="400"
              />
              <vue-image-slider
                class="mx-auto block md:hidden"
                :images="imageArray"
                :intervalVal="3000"
                :height="160"
                :width="250"
              />
            </div>
          </div>
          <div slot="leftBtn"></div>
          <div slot="rightBtn"></div>
        </ModalsConfirm>
        <!-- profile picture end -->
      </div>
    </div>
  </div>
</template>

<script>
import HeaderGlobal from "../../header/HeaderGlobal.vue";
import ModalsConfirm from "../../modals/ModalsConfirm.vue";
import BorderedButton from "../../buttons/BorderedButton.vue";
import SubmitButton from "../../buttons/SubmitButton.vue";
import FormError from "../../warning/FormError.vue";
import VueImageSlider from "vue-image-slider";
import StarRating from "vue-star-rating";
import Loader from "../../loader/Loader.vue";
import {
  getPetProfileApi,
  petBreedPdfLink,
} from "../../../services/petRecordService";
import { pictureByNameSpecies } from "../../../methods/petMethods";
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["privat"],
  components: {
    HeaderGlobal,
    ModalsConfirm,
    VueImageSlider,
    StarRating,
    BorderedButton,
    SubmitButton,
    FormError,
    Loader,
  },
  data() {
    return {
      riskScoreModals: false,
      emergencyContactModals: false,
      profilePictureModals: false,
      petData: {},
      picture: null,
      owner: {},
      titlemenu2: "",
      title: "",
      note: "",
      readMoreModals: false,
      breedPdf: "http://www.fci.be/en/nomenclature/ENGLISH-POINTER-1.html",
    };
  },
  computed: {
    ...mapGetters({
      petProfileData: "petRecord/getPetProfile",
      emergencyContacts: "contact/getEmergencyContacts",
      favouriteServices: "contact/getFavouriteServices",
    }),
    errorForm() {
      return this.$store.state.errorForm;
    },
    showLoaderComp() {
      return this.$store.state.showLoaderState;
    },
    biometricData() {
      if (this.petData) {
        return this.petData["Biometric Data"];
      }
    },
    ownerData() {
      if (this.petData) return this.petData["owner"];
    },
    imageArray() {
      const imageArray = [];
      if (this.petData) {
        if (this.petData.gallery) {
          this.petData.gallery.map((item) => imageArray.push(item.petImage));
        }
      }
      return imageArray;
    },
    symptomTracker() {
      if (this.petData) {
        return this.petData["Symptom Tracker"]
          ? this.petData["Symptom Tracker"]
          : {};
      }
    },
    careRoutine() {
      if (this.petData) {
        return this.petData["Care Routine"] ? this.petData["Care Routine"] : {};
      }
    },
    connectRequestModals() {
      return this.$store.state.addNonContactRequestState;
    },
  },
  watch: {
    biometricData: function () {
      this.$store.commit("showLoader", false);
    },
  },

  async created() {
    await getPetProfileApi(this.$route.params["code10"])
      .then((response) => {
        console.log(response.data);
        this.petData = response.data;
        this.titlemenu2 = this.petData["Biometric Data"]
          ? this.petData["Biometric Data"].callName
          : "";
        this.title = this.petData["Biometric Data"]
          ? this.$t('pet_dropdown_menu_public_profile')
          : "";

        this.picture = this.petData["Biometric Data"].profile_picture
          ? this.petData["Biometric Data"].profile_picture
          : pictureByNameSpecies(this.petData["Biometric Data"]);

        // this.getEmergencyContacts(localStorage.getItem("email"));
      })
      .catch((error) => {
        console.log(error.response);
        const expectedError =
          error.response &&
          error.response.data.code >= 300 &&
          error.response.data.code < 500;
        console.log(expectedError);
        if (!expectedError) {
          this.$store.commit("showLoader", false);
          this.$store.commit("errorForm", "An unexpected error occurrred.");
          window.scrollTo(0, 0);
        } else {
          console.log("here");
          this.$store.commit("showLoader", false);
          this.$store.commit(
            "errorForm",
            error.response.data.message || error.response.data
          );
          const err = error.response.data.code;
          console.log(err);

          window.scrollTo(0, 0);
        }
      });

    /*     Care Routine
: 
[]
Symptom Tracker
: 
[]
Treatment Plan
: 
[]
gallery
: 
(2) [{…}, {…}]
owner
: 
emailAddress
: 
(...)
firstName
: 
"selma"
lastName
: 
"selma"
profileImage
: */
  },

  watch: {
    petData: function () {
      this.$store.commit("showLoader", false);
    },
  },

  methods: {
    ...mapActions({
      getPetProfile: "petRecord/getPetProfile",
      getEmergencyContacts: "contact/getEmergencyContacts",
    }),
    goToCareRoutine() {
      //await petRecordById(this.petData.petID).then((result) => {
      /*  this.$router.push({
        path: "/routineoverview/public/" + this.petData.petID,
      }); */
      this.$router.push({
        name: "RoutineOverviewPublic",
        params: { data: this.petData },
      });
      //});
    },
    goToMedicalHistory() {
      //await petRecordById(this.petData.petID).then((result) => {

      this.$router.push({
        name: "MedicalHistoryDashboard",
        params: { data: this.petData },
      });

      /*   this.$router.push({
        name: "RoutineOverviewPet",
        params: { data: this.petData },
      }); */
      //});
    },

    openConnectRequestModals() {
      this.$store.commit("addNonContactRequestModals", true);
    },

    closeContactRequest() {
      this.note = "";
      this.$store.commit("errorForm", "");
      this.$store.commit("addNonContactRequestModals", false);
    },
    async openReadMoreModals() {
      const { data: response } = await petBreedPdfLink(1);
      console.log(response);
      window.open(response.BreedPdf, "_blank");
    },
  },
};
</script>

<style scoped></style>

  