<template>
  <div class="w-full lg:w-3/12 px-2">
    <div class="mt-10 lg:mt-0 bg-white rounded-md py-4 text-left">
      <div class="px-4 border-l-4 border-primary">
        <div>
          <h5 class="font-proximaMedium text-subheaderText">
            Bloodline Statistics
          </h5>
          <ul class="mt-1 font-proximaLight text-sm">
            <li>*Average life expectancy: 9.7 years</li>
            <li>*Most common cause of death: cancer</li>
            <li>*Average litter size: 8</li>
            <li>*Price Range: € 1000 - 2000</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="mt-10 bg-white rounded-md py-4 text-left">
      <div class="px-4 border-l-4 border-primary">
        <div>
          <h5 class="font-proximaMedium text-subheaderText">
            Top 3 Litter Ancestors
          </h5>
          <ul class="mt-1 font-proximaLight text-sm">
            <li>Nello’s Lex Luthor</li>
            <li>Jessy de Vulcano</li>
            <li>Cambria’s Cactus Jack</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="mt-10 bg-white rounded-md py-4 text-left">
      <div class="px-4 border-l-4 border-primary">
        <div>
          <h5 class="font-proximaMedium text-subheaderText">Past Litters</h5>
          <ul class="mt-1 font-proximaLight text-sm">
            <li>27.01.2014 - A Litter:</li>
            <li class="text-primary">Megan (Diaz) x DJ dei Nobilli Nati</li>
            <li>8 / 1</li>
          </ul>
          <ul class="mt-6 font-proximaLight text-sm">
            <li>13.10.2018 - B Litter</li>
            <li class="text-primary">
              Megan (Diaz) x Come As You are in Charge
            </li>
            <li>8 / 1</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>