<template>
  <div class="w-full lg:w-2/12 px-2">
    <div class="mt-10 lg:mt-0 bg-pastelOrange rounded-md py-4 text-left">
      <div class="px-4 border-l-4 border-orange">
        <div>
          <h5 class="font-proximaMedium text-subheaderText">Next Puppy Plan</h5>
          <p class="font-proximaLight my-1 text-sm">Q3 2024</p>
          <p class="font-proximaLight text-sm">Monflo's Rudra x?</p>
        </div>
      </div>
    </div>
    <div class="mt-10 bg-white rounded-md py-4 text-left">
      <div class="px-4 border-l-4 border-primary">
        <div>
          <h5 class="font-proximaMedium text-subheaderText">Health Tests</h5>
          <ul class="mt-1 font-proximaLight text-sm">
            <li>*Echo</li>
            <li>*24 hour hotler</li>
            <li>*Eyes</li>
            <li>*Thyroid</li>
            <li>*VWD</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="mt-10 bg-white rounded-md py-4 text-left">
      <div class="px-4 border-l-4 border-orange relative">
        <img
          @click="studDogsModals = true"
          class="cursor-pointer absolute top-0 right-2"
          src="@/assets/images/edit-icon.png"
          alt="edit-icon"
        />
        <div>
          <h5 class="font-proximaMedium text-subheaderText">Stud Dogs</h5>
          <h5 class="mt-1 text-sm font-proximaLight">WAC</h5>
          <p class="text-sm text-primary font-proximaLight">
            Black Athirats Anarchic Mr Baby
          </p>
          <h5 class="mt-1 text-sm font-proximaLight">Mexican CH</h5>
          <p class="text-sm text-primary font-proximaLight">
            Black Athirats Anarchic Mr Baby
          </p>
        </div>
      </div>
    </div>
    <div class="mt-10 bg-white rounded-md py-4 text-left">
      <div class="px-4 border-l-4 border-primary relative">
        <img
          class="cursor-pointer absolute top-0 right-2"
          src="@/assets/images/edit-icon.png"
          alt="edit-icon"
          @click="frozenSemenModals = true"
        />
        <div>
          <h5 class="font-proximaMedium text-subheaderText">Frozen Semen</h5>
          <p class="text-sm text-primary font-proximaLight">Mushu (Galvez)</p>
        </div>
      </div>
    </div>

    <!-- frozen semen modals -->
    <ModalsConfirm :show="frozenSemenModals">
      <div slot="actionQuestion" class="relative">
        <div
          class="
            absolute
            -top-5
            -right-5
            text-2xl
            font-proximaSemibold
            cursor-pointer
          "
          @click="frozenSemenModals = false"
        >
          ✖
        </div>
        <h3 class="font-proximaMedium text-2xl font-bold text-subheaderText">
          Frozen Semen
        </h3>
      </div>
      <div
        slot="actionText"
        class="w-full mt-10 flex flex-col items-center justify-center"
      >
        <div
          v-for="(data, i) in frozenSemenData"
          :key="i"
          class="
            w-full
            px-6
            py-4
            my-1
            font-proximaLight
            text-sm text-subheaderText
            flex
            items-center
            justify-between
            bg-lightGrey20
            rounded-md
          "
        >
          <span>{{ data }}</span>
          <TrashIcon
            class="cursor-pointer"
            :height="30"
            :width="30"
            bgColor="#fafafa"
            iconColor="#0AC0CB"
          />
        </div>
        <div
          class="
            w-8/12
            mt-10
            flex
            items-center
            justify-between
            font-proximaMedium
            text-sm text-subheaderText
            font-light
          "
        >
          <div
            @click="openNewRecord"
            class="flex flex-col items-center justify-center cursor-pointer"
          >
            <div class="w-max p-6 bg-primary rounded-full">
              <img
                class="m-auto"
                src="@/assets/images/add-white.png"
                alt="add-white"
              />
            </div>
            <span class="mt-4">Add New Record</span>
          </div>
          <div
            @click="openSearchRecord"
            class="flex flex-col items-center justify-center cursor-pointer"
          >
            <div class="w-max p-6 bg-primary rounded-full">
              <img src="@/assets/images/search-white.png" alt="add-white" />
            </div>
            <span class="mt-4">Search for Record</span>
          </div>
        </div>
      </div>
      <div slot="leftBtn"></div>
      <div slot="rightBtn"></div>
    </ModalsConfirm>
    <!-- frozen semen modals end -->

    <!-- new semen records modals -->
    <ModalsConfirm :show="newSemenRecordModals">
      <div slot="actionQuestion" class="relative">
        <div
          class="
            absolute
            -top-5
            -right-5
            text-2xl
            font-proximaSemibold
            cursor-pointer
          "
          @click="newSemenRecordModals = false"
        >
          ✖
        </div>
        <h3 class="font-proximaMedium text-2xl font-bold text-subheaderText">
          Create Simplified Record
        </h3>
        <p class="mt-2 font-proximaLight text-sm text-subheaderText">
          You may create a simple pet record for frozen semen or stud dogs which
          you do not own yet want to track in your litter records.
        </p>
      </div>
      <div
        slot="actionText"
        class="w-full mt-10 flex flex-col items-center justify-center"
      >
        <InputForm
          label="Registered Name"
          placeholder=""
          v-model="registName"
          classAdd="w-full mr-2 flex flex-col justify-start items-start"
          inputClass="bg-lightGrey20"
        />
        <div class="mt-4 w-full flex flex-col md:flex-row justify-between">
          <InputForm
            label="Pedigree Number"
            placeholder=""
            v-model="pedNumber"
            classAdd="w-full mr-2 flex flex-col justify-start items-start"
            inputClass="bg-lightGrey20"
          />
          <div
            class="
              w-full
              mt-4
              md:mt-0
              ml-0
              md:ml-2
              flex flex-col
              justify-start
              items-start
            "
          >
            <label class="text-sm font-proximaMedium" for="dateOfBirth">{{
              $t("global_date_of_birth")
            }}</label>
            <date-picker
              type="date"
              format="DD/MM/YYYY"
              v-model="dob"
              @change="onChange()"
              placeholder="Select date"
              class="w-full rounded-sm my-1 text-sm"
            ></date-picker>
          </div>
        </div>
        <InputForm
          label="Registered Name of Sire"
          placeholder=""
          v-model="registNameSire"
          classAdd="w-full mr-2 flex flex-col justify-start items-start"
          inputClass="bg-lightGrey20"
        />
        <InputForm
          label="Registered Name of Dam"
          placeholder=""
          v-model="registNameDam"
          classAdd="w-full mr-2 flex flex-col justify-start items-start"
          inputClass="bg-lightGrey20"
        />
        <div class="mt-4 w-full flex flex-col md:flex-row justify-between">
          <InputForm
            label="URL to Pedigree"
            placeholder=""
            v-model="pedUrl"
            classAdd="w-full mr-2 flex flex-col justify-start items-start"
            inputClass="bg-lightGrey20"
          />
          <div
            class="
              w-full
              mt-4
              md:mt-0
              ml-0
              md:ml-2
              flex flex-col
              justify-start
              items-start
            "
          >
            <label class="text-sm font-proximaMedium" for="origin">{{
              $t("global_country_of_origin")
            }}</label>
            <select class="w-full rounded-sm px-3 py-3 my-1 text-sm">
              <option class="text-lightGrey" value="" disabled selected>
                {{ $t("global_select_country") }}
              </option>
              <option
                v-for="item in countriesList"
                :value="item.id"
                :key="item.id"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="mt-4 w-full flex flex-col md:flex-row justify-between">
          <InputForm
            label="Owner's Email Address"
            placeholder=""
            v-model="ownerEmail"
            classAdd="w-full mr-2 flex flex-col justify-start items-start"
            inputClass="bg-lightGrey20"
          />
          <InputForm
            label="Owner's Name"
            placeholder=""
            v-model="ownerName"
            classAdd="w-full mt-4 md:mt-0 ml-0 md:ml-2 flex flex-col justify-start items-start"
            inputClass="bg-lightGrey20"
          />
        </div>
        <div class="flex items-center justify-between">
          <InputImagePets class="mx-1 mt-4" @input="pushToGallery" />
          <InputImagePets class="mx-1 mt-4" @input="pushToGallery" />
        </div>
      </div>
      <div slot="leftBtn">
        <BorderedButton
          class="mr-1"
          :title="$t('global_cancel')"
          borderColor="border-darkGrey"
          textCl="text-darkGrey"
          paddingX="px-8"
          width="w-full md:w-10/12"
          @click.native="newSemenRecordModals = false"
        />
      </div>
      <div slot="rightBtn">
        <SubmitButton
          class="ml-1"
          title="Confirm"
          background="bg-success"
          paddingX="px-8"
          width="w-full md:w-10/12"
        />
      </div>
    </ModalsConfirm>
    <!-- new semen records modals end -->

    <!-- search record modals -->
    <ModalsConfirm :show="searchRecordsModals">
      <div slot="actionQuestion" class="relative">
        <div
          class="
            absolute
            -top-5
            -right-5
            text-2xl
            font-proximaSemibold
            cursor-pointer
          "
          @click="searchRecordsModals = false"
        >
          ✖
        </div>
        <h3 class="font-proximaMedium text-2xl font-bold text-subheaderText">
          Search for Record
        </h3>
      </div>
      <div
        slot="actionText"
        class="w-full mt-10 flex flex-col items-center justify-center"
      >
        <div class="w-full lg:w-10/12">
          <InputForm
            label="Enter Dog's Registered Name:"
            placeholder="Black athirat"
            v-model="registName"
            classAdd="w-full mr-2 flex flex-col justify-start items-start"
            inputClass="bg-lightGrey20"
          />
        </div>
        <ul
          class="w-full lg:w-10/12 h-64 mt-4 overflow-y-auto overflow-x-hidden"
        >
          <li
            v-for="(data, i) in frozenSemenRecord"
            :key="i"
            class="
              w-full
              my-4
              flex
              items-start
              justify-between
              font-proximaMedium
              text-left
            "
          >
            <div>
              <h3 class="text-base text-primary">{{ data.name }}</h3>
              <span class="font-proximaLight text-subheaderText">{{
                data.notes
              }}</span>
            </div>
            <InputRadioEach :id="i" />
          </li>
        </ul>
      </div>
      <div slot="leftBtn">
        <BorderedButton
          class="mr-1"
          :title="$t('global_cancel')"
          borderColor="border-darkGrey"
          textCl="text-darkGrey"
          paddingX="px-8"
          width="w-full mx-auto md:w-10/12"
          @click.native="searchRecordsModals = false"
        />
      </div>
      <div slot="rightBtn">
        <SubmitButton
          class="ml-1"
          title="Add"
          background="bg-success"
          paddingX="px-8"
          width="w-full mx-auto md:w-10/12"
        />
      </div>
    </ModalsConfirm>
    <!-- search record modals end -->

    <!-- stud dogs modals -->
    <ModalsConfirm :show="studDogsModals">
      <div slot="actionQuestion" class="relative">
        <h1 class="text-2xl text-subheaderText">Stud Dogs</h1>
        <span class="text-sm font-proximaLight"
          >Select the male dogs from your pet records that are active at stud.
        </span>
      </div>
      <div slot="actionText" class="mt-6">
        <div
          class="my-2 flex items-center justify-between"
          v-for="(activeBreed, i) in activeBreeds"
          :key="i"
        >
          <div class="flex items-center">
            <img
              class="h-6"
              src="@/assets/images/profilepic-icon.png"
              alt="profilepic-icon"
            />
            <span class="ml-2">{{ activeBreed.name }}</span>
          </div>
          <RadioOnoffBorder
            :checked="activeBreed.checked"
            :checkAll="checkAll"
            @inputVal="inputVal"
          />
        </div>
        <div class="w-full md:w-11/12 mt-4 flex items-end justify-end">
          <label class="cont">
            <input type="checkbox" @change="checkAllFunc($event)" />
            <span class="checkmark"></span>
          </label>
          <span class="pl-2 font-proximaLight text-md">Select all</span>
        </div>
      </div>
      <div slot="leftBtn">
        <BorderedButton
          class="mr-1"
          :title="$t('global_cancel')"
          borderColor="border-darkGrey"
          textCl="text-darkGrey"
          paddingX="px-8"
          width="w-full md:w-10/12"
          @click.native="studDogsModals = false"
        />
      </div>
      <div slot="rightBtn">
        <SubmitButton
          @click.native="post"
          class="ml-1"
          title="Confirm"
          background="bg-success"
          paddingX="px-8"
          width="w-full md:w-10/12"
        />
      </div>
    </ModalsConfirm>
    <!-- stud dogs modals -->
  </div>
</template>

<script>
import ModalsConfirm from "../../modals/ModalsConfirm.vue";
import TrashIcon from "../../icons/TrashIcon.vue";
import AddIcon from "../../icons/AddIcon.vue";
import InputForm from "../../forms/InputForm.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import SubmitButton from "../../buttons/SubmitButton.vue";
import BorderedButton from "../../buttons/BorderedButton.vue";
import InputImagePets from "../../forms/InputImagePets.vue";
import InputRadioEach from "../../forms/InputRadioEach.vue";
import RadioOnoffBorder from "../../forms/RadioOnoffBorder.vue";

import { getCountries } from "../../../services/countriesServices";

export default {
  components: {
    ModalsConfirm,
    TrashIcon,
    AddIcon,
    InputForm,
    DatePicker,
    SubmitButton,
    BorderedButton,
    InputImagePets,
    InputRadioEach,
    RadioOnoffBorder,
  },
  data() {
    return {
      frozenSemenModals: false,
      newSemenRecordModals: false,
      searchRecordsModals: false,
      studDogsModals: false,
      checkAll: false,
      countriesList: [],
      registName: "",
      pedNumber: "",
      dob: "",
      registNameSire: "",
      registNameDam: "",
      pedUrl: "",
      countryOrigin: "",
      ownerEmail: "",
      ownerName: "",
      frozenSemenData: [
        "Black Athirats Anarchic Mr Baby",
        "Black Athirats Angry Goblin King",
        "Black Athirats Anarchic Rudra",
      ],
      frozenSemenRecord: [
        {
          name: "Black Athirat Anarchic Mr Baby",
          notes: "b. 27.01.2014 - JrCh Bulgaria",
        },
        {
          name: "Black Athirat Arrogant Goblin King",
          notes: "b. 27.01.2014 - Perro de Protección Brevet",
        },
        {
          name: "Black Athirat Anarchic Mr Baby",
          notes: "b. 27.01.2014 - JrCh Bulgaria",
        },
        {
          name: "Black Athirat Arrogant Goblin King",
          notes: "b. 27.01.2014 - Perro de Protección Brevet",
        },
        {
          name: "Black Athirat Anarchic Mr Baby",
          notes: "b. 27.01.2014 - JrCh Bulgaria",
        },
        {
          name: "Black Athirat Arrogant Goblin King",
          notes: "b. 27.01.2014 - Perro de Protección Brevet",
        },
      ],
      activeBreeds: [
        {
          id: "1",
          name: "Korax",
          checked: false,
        },
        {
          id: "2",
          name: "Boogieman",
          checked: false,
        },
        {
          id: "3",
          name: "Yan",
          checked: false,
        },
        {
          id: "4",
          name: "Bini",
          checked: false,
        },
        {
          id: "5",
          name: "Dodger",
          checked: false,
        },
      ],
    };
  },
  async mounted() {
    const a = await getCountries();
    this.countriesList = a.data;
  },
  methods: {
    openNewRecord() {
      this.frozenSemenModals = false;
      this.newSemenRecordModals = true;
    },
    openSearchRecord() {
      this.frozenSemenModals = false;
      this.searchRecordsModals = true;
    },
    onChange() {
      this.petData.dateOfBirth = moment(String(this.dateBirth)).format(
        "DD/MM/YYYY"
      );
    },
    pushToGallery(val) {
      this.petGallery.push({ petImage: val, primary_picture: 0 });
    },
    inputVal(val, checked) {
      this.checked = checked;
      this.$emit("settingVal", val.id, checked);
    },
    checkAllFunc(e) {
      // console.log(e.originalTarget.checked);
      this.checkAll = e.originalTarget.checked;
    },
  },
};
</script>

<style>
.mx-input {
  border: none;
  background: #ebedef;
  border-radius: 2px;
  padding: 22px 16px !important;
  color: #9ca3af;
}
.mx-datepicker-popup {
  z-index: 9999;
}
</style>