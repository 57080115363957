<template autocomplete="on">
  <Loader class="mt-6 flex justify-center" v-if="showLoader" />
  <div v-else>
    <div class="flex justify-start items-end">
      <div>
        <HeaderGlobal
          titlemenu1="Pet Records"
          :to1="'/sppassivedashboard/new'"
          :titlemenu2="titlemenu2"
          :titlemenu3="$t('pet_dropdown_menu_public_profile')"
          class="pl-4"
        />
      </div>
    </div>
    <div class="mt-12 flex flex-col justify-center items-center">
      <div class="w-full font-proximaMedium">
        <h3 class="mb-5 text-2xl uppercase">{{ biometricData.callName }}</h3>
        <div class="w-full flex items-start justify-evenly">
          <div
            class="relative cursor-pointer"
            @click="profilePictureModals = true"
          >
            <img
              class="mt-6 border border-primary rounded-full"
              style="height: 102px; width: 102px"
              :src="picture"
              alt="pet_icon"
            />
              <img v-if="ownerData.profileImage" class="
                w-12
                h-12
                border border-white
                rounded-full
                absolute
                top-2
                -right-3
              " :src="ownerData.profileImage" alt="profile icon" /> 
            <img
            v-else
              class="w-12 h-12 border border-white rounded-full absolute top-2 -right-3"
              src="@/assets/images/profilepic-icon.png"
              alt="profile icon"
            />
          </div>
          <ul class="text-left font-proximaLight list-disc">
            <li class="my-2 text-primary">
              <!--  <span class="text-black">{{
                ownerData.firstName + " " + ownerData.lastName
              }}</span> -->
            </li>
            <li class="my-2 text-primary">
              <span class="text-black">{{ biometricData.age }}</span>
            </li>
            <li class="my-2 text-primary">
              <span class="text-black">{{ biometricData.breeding_sex }}</span>
            </li>
            <li v-if="biometricData.height_weight" class="my-2 text-primary">
              <span class="text-black">{{ biometricData.height_weight }}</span>
            </li>
            <!--   <li class="my-2 text-primary">
              <span class="text-black">{{ biometricData.blood }}</span>
            </li> -->
          </ul>
        </div>
        <div class="mt-8 flex items-start justify-between">
          <ul class="w-5/12">
            <li class="flex items-center justify-between">
              <span class="pt-1">Manners</span>
              <star-rating
                class="flex justify-center"
                :star-size="18"
                :rating="4"
                :read-only="true"
                :show-rating="false"
                :increment="0.01"
                active-color="#354E57"
              />
            </li>
            <li class="flex items-center justify-between">
              <span class="pt-1">Friendliness</span>
              <star-rating
                class="flex justify-center"
                :star-size="18"
                :rating="3"
                :read-only="true"
                :show-rating="false"
                :increment="0.01"
                active-color="#354E57"
              />
            </li>
            <li class="flex items-center justify-between">
              <span class="pt-1">Energy</span>
              <star-rating
                class="flex justify-center"
                :star-size="18"
                :rating="4.7"
                :read-only="true"
                :show-rating="false"
                :increment="0.01"
                active-color="#354E57"
              />
            </li>
            <li class="flex items-center justify-between">
              <span class="pt-1">Compliance</span>
              <star-rating
                class="flex justify-center"
                :star-size="18"
                :rating="2.3"
                :read-only="true"
                :show-rating="false"
                :increment="0.01"
                active-color="#354E57"
              />
            </li>
            <li class="flex items-center justify-between">
              <span class="pt-1">Punctuality</span>
              <star-rating
                class="flex justify-center"
                :star-size="18"
                :rating="4"
                :read-only="true"
                :show-rating="false"
                :increment="0.01"
                active-color="#354E57"
              />
            </li>
          </ul>
          <!--  <div class="w-5/12 flex flex-col items-center justify-center">
            <div class="flex items-center cursor-pointer" @click="riskScoreModals = true">
              <h5 class="text-sm">Risk Score</h5>
              <img class="h-4 w-4" src="@/assets/images/icons/questionmark-sm-icon.png" alt="questionmark-sm-icon" />
            </div>
            <h1 class="mt-4 text-6xl">9.0</h1>
          </div> -->
        </div>
        <!--   <div>
          <ul class="mt-4 flex flex-wrap">
            <li
              class="mt-6 w-6/12 flex flex-col items-center cursor-pointer"
              @click="emergencyContactModals = true"
            >
              <img
                class="mb-2 h-28 w-28"
                src="@/assets/images/emergencycontact-icon.png"
                alt="emergencycontact-icon"
              />
              <span>Emergency Contacts</span>
            </li>
            <li
              class="mt-6 w-6/12 flex flex-col items-center cursor-pointer"
              v-on:click="goToCareRoutine"
            >
              <img
                class="mb-2 h-28 w-28"
                src="@/assets/images/careroutine-icon.png"
                alt="careroutine-icon"
              />
              <span>{{ $t("pet_dropdown_menu_care_routine") }}</span>
            </li>
          </ul>
        </div> -->
        <div class="mt-6">
          <ul class="mt-6 flex flex-wrap">
            <li
              class="mt-3 w-3/12 flex flex-col items-center cursor-pointer"
              @click="goToCareBlog"
            >
              <!--  @click="emergencyContactModals = true" -->
              <img
                class="mb-2 h-28 w-28"
                src="@/assets/images/careblog-icon.png"
                alt="medicalhistory-icon"
              />
              <span>Diary</span>
            </li>
            <li
              class="mt-3 w-3/12 flex flex-col items-center cursor-pointer"
              @click="goToMedicalHistory"
            >
              <!--  v-on:click="goToCareRoutine" -->

              <img
                class="mb-2 h-28 w-28"
                src="@/assets/images/medicalhistory-icon.png"
                alt="medicalhistory-icon"
              />
              <span>Medical History</span>
            </li>
            <li
              class="mt-3 w-3/12 flex flex-col items-center cursor-pointer"
              @click="emergencyContactModals = true"
            >
              <img
                class="mb-2 h-28 w-28"
                src="@/assets/images/emergencycontact-icon.png"
                alt="emergencycontact-icon"
              />
              <span>Emergency Contacts</span>
            </li>
            <li
              class="mt-3 w-3/12 flex flex-col items-center cursor-pointer"
              v-on:click="goToCareRoutine"
            >
              <img
                class="mb-2 h-28 w-28"
                src="@/assets/images/careroutine-icon.png"
                alt="careroutine-icon"
              />
              <span>{{ $t("pet_dropdown_menu_care_routine") }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- emergency contact -->
    <ModalsConfirm :show="emergencyContactModals">
      <div slot="actionQuestion" class="relative">
        <div
          class="absolute -top-0 right-0 text-2xl font-proximaSemibold cursor-pointer"
          @click="emergencyContactModals = false"
        >
          ✖
        </div>
      </div>
      <div slot="actionText" class="mt-8 mb-4">
        <div>
          <h3 class="mb-4 text-2xl font-bold font-proximaMedium">
            Emergency Contacts
          </h3>
          <div class="h-52 overflow-y-auto">
            <div
              class="mb-4 text-left flex items-center"
              v-for="(data, i) in emergencyContacts"
              :key="i"
            >
              <img
                v-if="data.profile_picture"
                class="h-10 w-10 rounded-full"
                :src="data.profile_picture"
                :alt="data.name"
              />
              <img
                v-else
                class="h-10 w-10"
                :src="require('@/assets/images/pp-3.png')"
                :alt="data.name"
              />
              <div class="pl-2">
                <h5 class="text-xl font-proximaNormal">
                  {{ data.name || data.contactEmailAddress }}
                  {{ data.contactphoneNo ? "(" : "" }}
                  <a :href="'tel:' + data.contactphoneNo">{{
                    data.contactphoneNo
                  }}</a>
                  {{ data.contactphoneNo ? ")" : "" }}
                </h5>
                <!-- <span>{{ data.address }}</span> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="leftBtn"></div>
      <div slot="rightBtn"></div>
    </ModalsConfirm>
    <!-- emergency contact end -->
    <!-- profile picture -->
    <ModalsConfirm :show="profilePictureModals">
      <div slot="actionQuestion" class="relative">
        <div
          class="absolute -top-0 right-0 text-2xl font-proximaSemibold cursor-pointer"
          @click="profilePictureModals = false"
        >
          ✖
        </div>
      </div>
      <div slot="actionText" class="mt-8 mb-4">
        <div>
          <vue-image-slider
            class="mx-auto hidden md:block"
            :images="imageArray"
            :intervalVal="3000"
            :height="300"
            :width="400"
          />
          <vue-image-slider
            class="mx-auto block md:hidden"
            :images="imageArray"
            :intervalVal="3000"
            :height="160"
            :width="250"
          />
        </div>
      </div>
      <div slot="leftBtn"></div>
      <div slot="rightBtn"></div>
    </ModalsConfirm>
    <!-- profile picture end -->
  </div>
</template>

<script>
import HeaderGlobal from "../../header/HeaderGlobal.vue";
import ModalsConfirm from "../../modals/ModalsConfirm.vue";
import { petRecordById } from "../../../services/petRecordService";
import VueImageSlider from "vue-image-slider";
import StarRating from "vue-star-rating";
import Loader from "../../loader/Loader.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  props: ["picture", "pet"],
  components: {
    HeaderGlobal,
    ModalsConfirm,
    VueImageSlider,
    Loader,
    StarRating,
  },
  data() {
    return {
      showLoader: true,
      favoriteServiceModals: false,
      emergencyContactModals: false,
      profilePictureModals: false,
      riskScoreModals: false,
    };
  },
  computed: {
    ...mapGetters({
      petProfileData: "petRecord/getPetProfile",
      emergencyContacts: "contact/getEmergencyContacts",
    }),

    biometricData() {
      console.log(this.petProfileData);
      if (this.petProfileData) {
        return this.petProfileData["Biometric Data"];
      }
    },

    ownerData() {
      console.log(this.petProfileData);
      if (this.petProfileData) return this.petProfileData["owner"];
    },

    imageArray() {
      const imgArray = [];

      if (this.pet.petGallery.length > 0) {
        this.pet.petGallery.map((i) => imgArray.push(i.petImage));
      }
      return imgArray;
    },

    titlemenu2() {
      return this.petProfileData
        ? this.petProfileData["Biometric Data"].species +
            " Record " +
            this.petProfileData["Biometric Data"].callName
        : "";
    },
  },
  watch: {
    biometricData: function () {
      this.showLoader = false;
    },
  },
  created() {
    this.getEmergencyContacts(localStorage.getItem("email"));
  },

  methods: {
    ...mapActions({
      getEmergencyContacts: "contact/getEmergencyContacts",
    }),
    async goToCareRoutine() {
      await petRecordById(this.pet.petID).then((result) => {
        this.$router.push({
          name: "RoutineOverviewPet",
          params: { data: result.data.petRecord },
        });
      });
    },
    async goToMedicalHistory() {
      this.$router.push({
        name: "MedicalHistoryDashboard",
        params: { data: this.petData },
      });
    },
    async goToCareBlog() {
      this.$router.push({
        path: "/careblog",
      });
    },
  },
};
</script>

<style scoped></style>
