<template>
  <div class="mt-2 mb-6 md:my-0 flex flex-col lg:flex-row items-start">
    <div class="h-full md:h-screen w-full px-4">
      <CreateBreeder
        v-if="routePath == 'createbreeder'"
      />
      <ProfileSection
        v-if="routePath == 'profile'"
      />
      <NavMenuSecondary page="breederprofile" />
    </div>
  </div>
</template>

<script>
import SectionLeft from "../components/SectionLeft.vue";
import ContactSection from "../components/publicprofile/contactview/ContactSection.vue";
import ServiceSection from "../components/publicprofile/serviceproviderview/ServiceSection.vue";
import NonContactSection from "../components/publicprofile/noncontactindividualuser/NonContactSection.vue";
import UnregisteredSection from "../components/publicprofile/unregisteredpetview/UnregisteredSection.vue";
import DataserviceLogo from "../components/DataserviceLogo.vue";

import CreateBreeder from "../components/breederprofile/breederview/CreateBreeder.vue"
import ProfileSection from "../components/breederprofile/ProfileSection.vue"

import NavMenuSecondary from "../components/navigationmenu/secondary/NavMenuSecondary.vue"

export default {
  components: {
    SectionLeft,
    ContactSection,
    ServiceSection,
    NonContactSection,
    UnregisteredSection,
    DataserviceLogo,
    CreateBreeder,
    ProfileSection,
    NavMenuSecondary
  },
  data() {
    return {
      routePath: "",
      picture: null,
    };
  },
  created() {
    const path = this.$router.currentRoute.params.id;

    this.routePath = path;
  },
  methods: {
    
  },
};
</script>
