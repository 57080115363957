<template>
  <div>
      <!-- titlemenu1="AI Scan" -->
    <div class="flex justify-start items-end">
      <div>
        <HeaderGlobal
        
          :titlemenu1="this.titlemenu2"
          class="pl-4"
        />
      </div>
    </div>
    <div class="mt-12 flex flex-col justify-center items-center">
      <div class="w-full font-proximaMedium relative">
        <div class="absolute">
          <img
            class="h-full w-full"
            src="@/assets/images/dialog-box.png"
            alt="dialog-box"
          />
        </div>
        <div
          class="
            pt-5
            md:pt-14
            w-full
            flex
            items-start
            justify-start
            relative
            z-10
          "
        >
          <div
            class="lg:mx-14 md:mx-12 mx-8 relative cursor-pointer"
            @click="profilePictureModals = true"
          >
            <img
              class="mt-6 border border-primary rounded-full"
              src="@/assets/images/peticons/dog_icon.png"
              alt="dog_icon"
            />
          </div>
          <ul
            class="
              lg:w-4/12
              md:w-5/12
              text-left text-sm
              md:text-base
              font-proximaLight
              list-disc
            "
          >
            <li class="my-2 text-primary">
              <span class="text-black font-bold">Owner</span>
            </li>
            <li class="my-2 text-primary">
              <span class="text-black font-bold">Height: </span
              ><span class="text-black">{{ petData.height_weight }}</span>
            </li>
            <li class="my-2 text-primary">
              <span class="text-black font-bold">Weight: </span
              ><span class="text-black">{{ petData.height_weight }}</span>
            </li>
            <li class="my-2 text-primary">
              <span class="text-black font-bold">Lifespan: </span
              ><span class="text-black">{{ petData.age }}</span>
            </li>
            <li class="my-2 text-primary">
              <span class="text-black font-bold">Temperament: </span
              ><span class="text-black"></span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div
      class="mt-16 md:mt-32 flex flex-col md:flex-row items-center md:items-end"
    >
      <img
        class="w-36"
        src="@/assets/images/standing-logo.png"
        alt="standing-logo"
      />
      <div
        class="
          w-max
          text-primary
          bg-white
          px-16
          py-4
          border border-primary
          rounded-md
        "
      >
        Read More About Breed
      </div>
    </div>
    <!-- favorite services -->
    <ModalsConfirm :show="favoriteServiceModals">
      <div slot="actionQuestion" class="relative">
        <div
          class="
            absolute
            -top-0
            right-0
            text-2xl
            font-proximaSemibold
            cursor-pointer
          "
          @click="favoriteServiceModals = false"
        >
          ✖
        </div>
      </div>
      <div slot="actionText" class="mt-8 mb-4">
        <div>
          <h3 class="mb-4 text-2xl font-bold font-proximaMedium">
            Favorite Services
          </h3>
          <div class="h-52 overflow-y-auto">
            <div
              class="mb-4 text-left flex items-center"
              v-for="(data, i) in favoriteData"
              :key="i"
            >
              <img
                class="h-10 w-10 rounded-full"
                :src="require('@/assets/images/' + data.image + '.png')"
                :alt="data.image"
              />
              <div class="pl-2">
                <h5 class="text-primary text-sm font-proximaMedium">
                  {{ data.name }}
                </h5>
                <span>{{ data.address }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="leftBtn"></div>
      <div slot="rightBtn"></div>
    </ModalsConfirm>
    <!-- favorite services end -->
    <!-- emergency contact -->
    <ModalsConfirm :show="emergencyContactModals">
      <div slot="actionQuestion" class="relative">
        <div
          class="
            absolute
            -top-0
            right-0
            text-2xl
            font-proximaSemibold
            cursor-pointer
          "
          @click="emergencyContactModals = false"
        >
          ✖
        </div>
      </div>
      <div slot="actionText" class="mt-8 mb-4">
        <div>
          <h3 class="mb-4 text-2xl font-bold font-proximaMedium">
            Emergency Contacts
          </h3>
          <div class="h-52 overflow-y-auto">
            <div
              class="mb-4 text-left flex items-center"
              v-for="(data, i) in emergencyData"
              :key="i"
            >
              <img
                class="h-10 w-10 rounded-full"
                :src="require('@/assets/images/' + data.image + '.png')"
                :alt="data.image"
              />
              <div class="pl-2">
                <h5 class="text-primary text-sm font-proximaMedium">
                  {{ data.name }}
                  ( <a :href="'tel:' + data.phone">{{ data.phone }}</a> )
                </h5>
                <span>{{ data.address }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="leftBtn"></div>
      <div slot="rightBtn"></div>
    </ModalsConfirm>
    <!-- emergency contact end -->
    <!-- profile picture -->
    <ModalsConfirm :show="profilePictureModals">
      <div slot="actionQuestion" class="relative">
        <div
          class="
            absolute
            -top-0
            right-0
            text-2xl
            font-proximaSemibold
            cursor-pointer
          "
          @click="profilePictureModals = false"
        >
          ✖
        </div>
      </div>
      <div slot="actionText" class="mt-8 mb-4">
        <div>
          <vue-image-slider
            class="mx-auto hidden md:block"
            :images="imageArray"
            :intervalVal="3000"
            :height="300"
            :width="400"
          />
          <vue-image-slider
            class="mx-auto block md:hidden"
            :images="imageArray"
            :intervalVal="3000"
            :height="160"
            :width="250"
          />
        </div>
      </div>
      <div slot="leftBtn"></div>
      <div slot="rightBtn"></div>
    </ModalsConfirm>
    <!-- profile picture end -->
  </div>
</template>

<script>
import HeaderGlobal from "../../header/HeaderGlobal.vue";
import ModalsConfirm from "../../modals/ModalsConfirm.vue";
import { getPetProfile } from "../../../services/petRecordService";

import VueImageSlider from "vue-image-slider";

export default {
  props: ["picture", "data"],
  components: {
    HeaderGlobal,
    ModalsConfirm,
    VueImageSlider,
  },
  data() {
    return {
      favoriteServiceModals: false,
      emergencyContactModals: false,
      profilePictureModals: false,
      imageArray: [
        "https://cdn.pixabay.com/photo/2015/12/12/15/24/amsterdam-1089646_1280.jpg",
        "https://cdn.pixabay.com/photo/2016/02/17/23/03/usa-1206240_1280.jpg",
        "https://cdn.pixabay.com/photo/2015/05/15/14/27/eiffel-tower-768501_1280.jpg",
        "https://cdn.pixabay.com/photo/2016/12/04/19/30/berlin-cathedral-1882397_1280.jpg",
      ],
      favoriteData: [
        {
          image: "pp-lg",
          name: "Hundesalon Bad Belzig",
          address: "45 Chatswood , Autraili-03470000000",
        },
        {
          image: "pp-lg",
          name: "Tierarztpraxis Moon",
          address: "45 Chatswood , Autraili-03470000000",
        },
        {
          image: "pp-lg",
          name: "Hundesalon Bad Belzig",
          address: "45 Chatswood , Autraili-03470000000",
        },
        {
          image: "pp-lg",
          name: "Hundesalon Bad Belzig",
          address: "45 Chatswood , Autraili-03470000000",
        },
      ],
      emergencyData: [
        {
          image: "profilepic-icon",
          name: "Aunty Thelma",
          phone: "02495464",
          address: "45 Chatswood , Autraili-03470000000",
        },
        {
          image: "profilepic-icon",
          name: "Mr Helmuth",
          phone: "045354364",
          address:
            "Neighbour on the right side, Zossener Str.56-58, 10969 Berlin, Germany",
        },
        {
          image: "profilepic-icon",
          name: "Dan Diaz",
          phone: "+52 45354364",
          address: "Ashra’s Breeder, Mexico D.F., Mexico",
        },
      ],
      titlemenu2: "Dog",
    };
  },

  async created() {
    const { data: response } = await getPetProfile(
      "95df4e28-895f-41d6-84e8-bc4f32b64676"
    );
    this.petData = response["Biometric Data"];
    this.titlemenu2 = this.petData
      ? this.petData.species + " Record " + this.petData.callName
      : "";
  },
};
</script>

<style scoped>
</style>