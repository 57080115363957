<template>
  <div>
    <!-- breeder profile modals -->
    <ModalsConfirm :show="createBreederModals">
      <div slot="actionQuestion" class="relative">
        <h1 class="mb-2 text-2xl">Create Breeder Profile</h1>
        <span class="text-sm font-proximaLight"
          >To create your public breeder profile, please confirm that the
          following information is still correct or adjust it.
        </span>
      </div>
      <div slot="actionText" class="mt-6">
        <div class="mt-4 w-full flex flex-col md:flex-row justify-between">
          <InputForm
            label="Phone number*"
            placeholder=""
            v-model="phoneNumber"
            classAdd="w-full mr-2 flex flex-col justify-start items-start"
            inputClass="bg-lightGrey20"
          />
          <InputForm
            label="Email address*"
            placeholder=""
            v-model="email"
            classAdd="w-full mt-4 md:mt-0 ml-0 md:ml-2 flex flex-col justify-start items-start"
            inputClass="bg-lightGrey20"
          />
        </div>
        <div class="mt-4 w-full flex flex-col md:flex-row justify-between">
          <InputForm
            label="Street address*"
            placeholder=""
            v-model="streetAddress"
            classAdd="w-full mr-2 flex flex-col justify-start items-start"
            inputClass="bg-lightGrey20"
          />
        </div>
        <div class="mt-4 w-full flex flex-col md:flex-row justify-between">
          <InputForm
            label="Postal code*"
            placeholder=""
            v-model="postalCode"
            classAdd="w-full mr-2 flex flex-col justify-start items-start"
            inputClass="bg-lightGrey20"
          />
          <InputForm
            label="City*"
            placeholder=""
            v-model="city"
            classAdd="w-full mt-4 md:mt-0 ml-0 md:ml-2 flex flex-col justify-start items-start"
            inputClass="bg-lightGrey20"
          />
        </div>
        <div class="mt-4 w-full flex flex-col md:flex-row justify-between">
          <InputForm
            label="State*"
            placeholder=""
            v-model="state"
            classAdd="w-full mr-2 flex flex-col justify-start items-start"
            inputClass="bg-lightGrey20"
          />
          <div
            class="
              w-full
              mt-4
              md:mt-0
              ml-0
              md:ml-2
              flex flex-col
              justify-start
              items-start
            "
          >
            <label class="text-sm font-proximaMedium" for="origin">{{
              $t("global_country_of_origin")
            }}</label>
            <select class="w-full rounded-sm px-3 py-3 my-1 text-sm">
              <option class="text-lightGrey" value="" disabled selected>
                {{ $t("global_select_country") }}
              </option>
              <option
                v-for="item in countriesList"
                :value="item.id"
                :key="item.id"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div slot="leftBtn">
        <BorderedButton
          class="mr-1"
          :title="$t('global_cancel')"
          borderColor="border-darkGrey"
          textCl="text-darkGrey"
          paddingX="px-8"
          width="w-full md:w-10/12"
          @click.native="createBreederModals = false"
        />
      </div>
      <div slot="rightBtn">
        <SubmitButton
          @click.native="openSelectActiveModals"
          class="ml-1"
          title="Confirm"
          background="bg-success"
          paddingX="px-8"
          width="w-full md:w-10/12"
        />
      </div>
    </ModalsConfirm>
    <!-- breeder profile modals end -->

    <!-- select active breeding dogs modals -->
    <ModalsConfirm :show="selectActiveBreedingModals">
      <div slot="actionQuestion" class="relative">
        <h1 class="mb-2 text-2xl">Select Active Breeding Dogs</h1>
        <span class="text-sm font-proximaLight"
          >Select the breeds and dogs that are active in reproduction.
        </span>
      </div>
      <div slot="actionText" class="mt-6">
        <h3 class="font-proximaMedium text-base">Active Breeds</h3>
        <div
          class="my-2 flex items-center justify-between"
          v-for="(activeBreed, i) in activeBreeds"
          :key="i"
        >
          <div class="flex items-center">
            <img
              class="h-6"
              src="@/assets/images/profilepic-icon.png"
              alt="profilepic-icon"
            />
            <span class="ml-2">{{ activeBreed.name }}</span>
          </div>
          <RadioOnoffBorder :checked="activeBreed.checked" :checkAll="checkAll" />
        </div>
      </div>
      <div slot="actionText" class="mt-6">
        <h3 class="font-proximaMedium text-base">Stud Dogs</h3>
        <div
          class="my-2 flex items-center justify-between"
          v-for="(studDog, i) in studDogs"
          :key="i"
        >
          <div class="flex items-center">
            <img
              class="h-6"
              src="@/assets/images/profilepic-icon.png"
              alt="profilepic-icon"
            />
            <span class="ml-2">{{ studDog.name }}</span>
          </div>
          <RadioOnoffBorder :checked="studDog.checked" :checkAll="checkAll" />
        </div>
      </div>
      <div slot="leftBtn">
        <BorderedButton
          class="mr-1"
          :title="$t('global_cancel')"
          borderColor="border-darkGrey"
          textCl="text-darkGrey"
          paddingX="px-8"
          width="w-full md:w-10/12"
          @click.native="backBreederModals"
        />
      </div>
      <div slot="rightBtn">
        <SubmitButton
          @click.native="post"
          class="ml-1"
          title="Confirm"
          background="bg-success"
          paddingX="px-8"
          width="w-full md:w-10/12"
        />
      </div>
    </ModalsConfirm>
    <!-- select active breeding dogs modals -->
  </div>
</template>

<script>
import ModalsConfirm from "../../modals/ModalsConfirm.vue";
import InputForm from "../../forms/InputForm.vue";
import SubmitButton from "../../buttons/SubmitButton.vue";
import BorderedButton from "../../buttons/BorderedButton.vue";
import RadioOnoffBorder from "../../forms/RadioOnoffBorder.vue";

import { getCountries } from "../../../services/countriesServices";

export default {
  components: {
    ModalsConfirm,
    SubmitButton,
    BorderedButton,
    InputForm,
    RadioOnoffBorder,
  },
  data() {
    return {
      createBreederModals: true,
      selectActiveBreedingModals: false,
      phoneNumber: "",
      email: "",
      streetAddress: "",
      postalCode: "",
      city: "",
      state: "",
      country: "",
      countriesList: [],
      checkAll: false,
      activeBreeds: [
        {
          id: "1",
          name: "Dobberman",
          checked: false,
        },
        {
          id: "2",
          name: "Beauceron",
          checked: false,
        },
        {
          id: "3",
          name: "Australian Stumpy Tall Cattle Dog",
          checked: false,
        },
        {
          id: "4",
          name: "Dogo Argentino",
          checked: false,
        },
        {
          id: "5",
          name: "German Wairehaired Pointer",
          checked: false,
        },
      ],
      studDogs: [
        {
          id: "1",
          name: "Korax",
          checked: false,
        },
        {
          id: "2",
          name: "Boogeyman",
          checked: false,
        },
        {
          id: "3",
          name: "Yan",
          checked: false,
        },
        {
          id: "4",
          name: "Bini",
          checked: false,
        },
        {
          id: "5",
          name: "Dodger",
          checked: false,
        },
      ],
    };
  },
  async mounted() {
    const a = await getCountries();
    this.countriesList = a.data;
  },
  methods: {
    openSelectActiveModals() {
      this.createBreederModals = false;
      this.selectActiveBreedingModals = true;
    },
    backBreederModals() {
      this.createBreederModals = true;
      this.selectActiveBreedingModals = false;
    }
  },
};
</script>

<style>
</style>