<template>
  <div>
    <div class="mb-10 w-full flex items-end justify-end">
      <div class="w-full lg:w-6/12">
        <HeaderGlobal
          titlemenu1="Community"
          :to1="'/communityoverview'"
          :titlemenu2="titlemenu2"
        />
      </div>
    </div>
    <h1 class="text-primary font-proximaMedium text-center font-bold text-2xl">
      Black Athirat Dobberman
    </h1>
    <div class="mt-8 flex flex-col lg:flex-row items-start justify-between">
      <FirstSection :breeders="breeders" />
      <SecondSection :breeders="breeders" />
      <ThirdSection :breeders="breeders" />
      <FourthSection :breeders="breeders" />
    </div>
  </div>
</template>

<script>
import FirstSection from "./sectionview/FirstSection.vue";
import SecondSection from "./sectionview/SecondSection.vue";
import ThirdSection from "./sectionview/ThirdSection.vue";
import FourthSection from "./sectionview/FourthSection.vue";
import HeaderGlobal from "../header/HeaderGlobal.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    FirstSection,
    SecondSection,
    ThirdSection,
    FourthSection,
    HeaderGlobal,
  },
  computed: {
    ...mapGetters({
      breeders: "breederdirectory/getBreeders",
    }),
    titlemenu2() {
      return "Dog Breeder Profile: " + this.breeders.kennelName;
    },
  },
  mounted() {
    this.getBreeders(localStorage.getItem("email"));
  },
  methods: {
    ...mapActions({
      getBreeders: "breederdirectory/getBreeders",
    }),
  },
};
</script>

<style>
</style>