<template>
  <div
    class="mt-10 lg:mt-0 w-full lg:w-5/12 h-full px-2 flex flex-col items-center justify-center"
  >
    <h1 class="text-subheaderText text-2xl font-bold font-proximaMedium">
      Owned Dogs
    </h1>
    <div class="flex flex-wrap justify-between">
      <div
        v-for="(data, i) in breeders.owned_pets"
        :key="i"
        class="w-6/12 mt-4 px-2"
      >
        <div class="">
          <img
            v-if="data.profile_picture"
            :src="data.profile_picture"
            :alt="data.pet.callName"
          />
          <img
            v-else
            :src="require('@/assets/images/dog-1.png')"
            :alt="data.pet.callName"
          />
          <div class="mt-2 text-sm font-proximaMedium">
            <h5 class="text-xs font-proximaLight">
              {{ data.race }}
            </h5>
            <h5 @click="openModals(data)" class="text-primary cursor-pointer">
              {{ data.pet.breed }}
            </h5>
            <h5 @click="openModals(data)" class="text-primary cursor-pointer">
              “{{ data.pet.callName }}”
            </h5>
          </div>
        </div>
      </div>
    </div>
    <ModalsConfirm :show="dogModals">
      <div slot="actionQuestion" class="relative">
        <div
          class="absolute -top-5 -right-5 text-2xl font-proximaSemibold cursor-pointer"
          @click="dogModals = false"
        >
          ✖
        </div>
        <h1 class="mb-2 text-2xl text-subheaderText font-bold">
          {{ dogData.breed }} "{{ dogData.name }}"
        </h1>
      </div>
      <div
        slot="actionText"
        class="w-full mt-10 flex items-center justify-between"
      >
        <div class="w-6/12 flex flex-col items-center justify-center">
          <div class="bg-primary-20 rounded-full p-8">
            <MedicalhistIcon :width="80" :height="80" />
          </div>
          <SubmitButton
            class=""
            title="Medical History"
            background="bg-primary"
            paddingX="px-8"
            width="w-10/12"
          />
        </div>
        <div class="w-6/12 flex flex-col items-center justify-center">
          <div class="bg-primary-20 rounded-full p-8">
            <OpenEye :width="80" :height="80" iconColor="#059AA3" />
          </div>
          <SubmitButton
            class="ml-1"
            title="Pet Profile"
            background="bg-primary"
            paddingX="px-8"
            width="w-10/12"
          />
        </div>
      </div>
      <div slot="leftBtn"></div>
      <div slot="rightBtn"></div>
    </ModalsConfirm>
  </div>
</template>

<script>
import ModalsConfirm from "../../modals/ModalsConfirm.vue";
import MedicalhistIcon from "../../icons/MedicalhistIcon.vue";
import OpenEye from "../../icons/OpenEye.vue";
import SubmitButton from "../../buttons/SubmitButton.vue";

export default {
  props: ["breeders"],
  components: {
    ModalsConfirm,
    MedicalhistIcon,
    OpenEye,
    SubmitButton,
  },
  data() {
    return {
      dogModals: false,
      dogData: {},
      dogs: [
        {
          race: "Balkan CH, GrCH Bulgaria, CH Cyprus, CH Moldova",
          breed: "Megan (Diaz)",
          name: "Ashra",
          picture: "dog-1",
        },
        {
          race: "Balkan CH, GrCH Bulgaria, CH Cyprus, CH Moldova",
          breed: "Black Athirat Anarchic Mr Baby",
          name: "Korax",
          picture: "dog-2",
        },
        {
          race: "Balkan CH, GrCH Bulgaria, CH Cyprus, CH Moldova",
          breed: "Monflo’s Rudra",
          name: "Rudra",
          picture: "dog-3",
        },
        {
          race: "Balkan CH, GrCH Bulgaria, CH Cyprus, CH Moldova",
          breed: "Black Athirat Angry Goblin King",
          name: "Jagger",
          picture: "dog-4",
        },
      ],
    };
  },
  methods: {
    openModals(i) {
      this.dogModals = true;
      this.dogData = i;
    },
  },
};
</script>

<style>
</style>